import { Component } from '@angular/core';
import { MediaGalleryComponent } from '@enginuity/media/organisms/media-gallery/media-gallery.component';
import { ModalService } from '@services/core-services/modal.service';

@Component({
  selector: 'app-media-gallery-dialog',
  imports: [MediaGalleryComponent],
  templateUrl: './media-gallery-dialog.component.html',
  styleUrl: './media-gallery-dialog.component.scss',
})
export class MediaGalleryDialogComponent {
  constructor(private readonly modal: ModalService) {}

  close() {
    this.modal.dismissAll();
  }
}
