<div class="d-flex flex-column w-100 spacing-12">
  <form [formGroup]="form" class="w-100 d-flex flex-column spacing-32" autocomplete="off">
    <div class="form-area w-100 d-flex flex-column spacing-24">
      <div class="d-flex align-items-start spacing-24 name-area">
        <app-inputs
          formControlName="first_name"
          Label="First Name"
          placeholder="First name"
          class="w-100"
          [state]="getState('first_name')"
          [error]="getErrors('first_name')"></app-inputs>

        <app-inputs
          formControlName="last_name"
          Label="Last Name"
          placeholder="Last name"
          class="w-100"
          [state]="getState('last_name')"
          [error]="getErrors('last_name')"></app-inputs>
      </div>
      <app-inputs
        type="email"
        formControlName="email"
        Label="E-mail"
        placeholder="Email"
        [state]="getState('email')"
        [error]="getErrors('email')"></app-inputs>

      <app-inputs
        type="password"
        formControlName="password"
        Label="Password"
        placeholder="Enter password here"
        [state]="getState('password')"
        [error]="getErrors('password')">
        <app-badges
          class="cues"
          cues
          [withIcon]="false"
          Type="tag"
          IconType="user"
          label="6+ letters /  1+ number / 1+ symbol"
          [ellipsis]="false"></app-badges>
      </app-inputs>
    </div>

    <app-buttons
      class="w-100"
      [type]="'button'"
      [active]="false"
      [disabled]="form.invalid"
      label="Create an account"
      [IconType]="processing ? 'loader' : 'import'"
      size="large"
      [Type]="processing ? 'onlyIcon' : 'withoutIcon'"
      (click)="handleSignup()"
      [shortcutKey]="false"></app-buttons>
  </form>
</div>
