<!-- commenting -->
@if (settings == menuSettings.StoreSetting) {
  <div class="side-nav">
    <div class="logo">
      <img class="cursor-pointer" height="27" width="24" alt="" ngSrc="/assets/logo.svg" priority />
    </div>
    <div class="side-nav-top">
      <ul class="sideMenu">
        <li class="menu-list">
          <a
            class="menu-link"
            routerLink="home"
            aria-label="Home"
            routerLinkActive="active-link"
            ariaCurrentWhenActive="page"
            tooltipClass="left"
            [matTooltip]="'Home'"
            [matTooltipPosition]="'right'">
            <app-ce-icon
              [name]="'home'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
          </a>
        </li>
      </ul>
      <div class="border-line"></div>
      <ul class="sideMenu">
        <li class="menu-list">
          <a class="menu-link" routerLinkActive="active-link" ariaCurrentWhenActive="page">
            <app-ce-icon
              [name]="'person'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
            <ul class="hover-menu custom-dropdown border-radius side-nav-list h3-bold primary-100">
              <li>
                <a class="cursor-pointer" routerLink="customers"
                  ><span>
                    <img alt="" height="17" width="16" alt="" ngSrc="/assets/person.svg" /></span
                  >Customers</a
                >
              </li>
              <li>
                <a class="cursor-pointer" routerLink="visitors"
                  ><span>
                    <img alt="" height="17" width="16" alt="" ngSrc="/assets/review.svg" /></span
                  >Visitors</a
                >
              </li>
              <li>
                <a class="cursor-pointer" routerLink="customers/attributes"
                  ><span
                    ><app-ce-icon [name]="'tasks'" [svg_class]="['primary-50']"></app-ce-icon></span
                  >Attributes</a
                >
              </li>
            </ul>
          </a>
        </li>
        <li class="menu-list">
          <a class="menu-link" routerLinkActive="active-link" ariaCurrentWhenActive="page">
            <app-ce-icon
              [name]="'product'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
            <ul class="hover-menu custom-dropdown border-radius side-nav-list h3-bold primary-100">
              <li>
                <a class="cursor-pointer" routerLink="products"
                  ><span
                    ><app-ce-icon [name]="'box'" [svg_class]="['primary-50']"></app-ce-icon></span
                  >Products</a
                >
              </li>
              <li>
                <a class="cursor-pointer" routerLink="products/categories"
                  ><span
                    ><app-ce-icon [name]="'list'" [svg_class]="['primary-50']"></app-ce-icon></span
                  >Categories</a
                >
              </li>
              <li>
                <a class="cursor-pointer" routerLink="products/attributes"
                  ><span
                    ><app-ce-icon [name]="'tasks'" [svg_class]="['primary-50']"></app-ce-icon></span
                  >Attributes</a
                >
              </li>
            </ul>
          </a>
        </li>
        <!-- TODO:
        <li class="menu-list">
          <a
            class="menu-link"
            ariaCurrentWhenActive="page"
            [ngClass]="{
              'active-link': isActive(active_link, ['orders', 'replacements', 'refunds'])
            }">
            <app-ce-icon [name]="'cart'" [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
            <ul class="hover-menu custom-dropdown border-radius side-nav-list h3-bold primary-100">
              <li>
                <a class="cursor-pointer" routerLink="/orders"
                  ><span
                    ><app-ce-icon
                      [name]="'cart'"
                      [svg_class]="['cursor-pointer', 'primary-50']"></app-ce-icon></span
                  >Orders</a
                >
              </li>
              <li>
                <a class="cursor-pointer" routerLink="/refunds"
                  ><span
                    ><app-ce-icon
                      [name]="'refund_icon'"
                      [svg_class]="['cursor-pointer', 'primary-50']"></app-ce-icon></span
                  >Refunds</a
                >
              </li>
              <li>
                <a class="cursor-pointer" routerLink="/replacements"
                  ><span
                    ><app-ce-icon
                      [name]="'refund_icon'"
                      [svg_class]="['cursor-pointer', 'primary-50']"></app-ce-icon></span
                  >Replacements</a
                >
              </li>
            </ul>
             <div class="tool d-none">
            <app-tooltip label="Orders" Type="default" size="medium" data-bs-placement="right"></app-tooltip>
          </div> 
          </a>
        </li> -->

        <li class="menu-list">
          <a
            class="menu-link"
            routerLink="orders"
            aria-label="Orders"
            routerLinkActive="active-link"
            ariaCurrentWhenActive="page"
            [matTooltip]="'Orders'"
            [matTooltipPosition]="'right'">
            <app-ce-icon
              [name]="'cart'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
          </a>
        </li>
        <!--TODO- <li class="menu-list">
          <a
            class="menu-link"
            routerLink="/subscriptions"
            aria-label="Subscriptions"
            ariaCurrentWhenActive="page"
            [ngClass]="{
              'active-link': isActive(active_link, ['subscriptions'])
            }">
            <app-ce-icon
              [name]="'recurring_invoice'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
            <div class="tool d-none">
              <app-tooltip
                label="Subscriptions"
                Type="default"
                size="medium"
                data-bs-placement="right"></app-tooltip>
            </div>
          </a>
        </li> -->
        <li class="menu-list">
          <a
            class="menu-link"
            routerLink="shipments"
            aria-label="Shipments"
            routerLinkActive="active-link"
            ariaCurrentWhenActive="page"
            [matTooltip]="'Shipping'"
            [matTooltipPosition]="'right'">
            <app-ce-icon
              [name]="'truck'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
          </a>
        </li>
      </ul>
      <div class="border-line"></div>
      <ul class="sideMenu">
        <li class="menu-list">
          <a
            class="menu-link"
            routerLink="payments"
            aria-label="Payments"
            routerLinkActive="active-link"
            ariaCurrentWhenActive="page"
            [matTooltip]="'Payments'"
            [matTooltipPosition]="'right'">
            <app-ce-icon
              [name]="'bank'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
          </a>
        </li>
        <li class="menu-list">
          <a class="menu-link" routerLinkActive="active-link" ariaCurrentWhenActive="page">
            <app-ce-icon
              [name]="'invoice'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
            <ul class="hover-menu custom-dropdown border-radius side-nav-list h3-bold primary-100">
              <li>
                <a class="cursor-pointer" routerLink="coupons"
                  ><span
                    ><app-ce-icon
                      [name]="'coupon'"
                      [svg_class]="['cursor-pointer', 'primary-50']"></app-ce-icon></span
                  >Coupons</a
                >
              </li>
              <li>
                <a class="cursor-pointer" routerLink="promotions"
                  ><span
                    ><app-ce-icon
                      [name]="'percentage'"
                      [svg_class]="['cursor-pointer', 'primary-50']"></app-ce-icon></span
                  >Promotions</a
                >
              </li>
            </ul>
          </a>
        </li>
        <li class="menu-list">
          <a class="menu-link" routerLinkActive="active-link" ariaCurrentWhenActive="page">
            <app-ce-icon
              [name]="'bar_graph'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
            <ul class="hover-menu custom-dropdown border-radius side-nav-list h3-bold primary-100">
              <li>
                <a class="cursor-pointer" routerLink="analytics"
                  ><span
                    ><app-ce-icon
                      [name]="'bar_graph'"
                      [svg_class]="['cursor-pointer', 'primary-50']"></app-ce-icon></span
                  >Analytics</a
                >
              </li>
              <li>
                <a class="cursor-pointer" routerLink="analytics/live-events"
                  ><span
                    ><app-ce-icon
                      [name]="'subscription'"
                      [svg_class]="['cursor-pointer', 'primary-50']"></app-ce-icon></span
                  >Live Events</a
                >
              </li>
            </ul>
          </a>
        </li>
      </ul>
      <div class="border-line"></div>
      <ul class="sideMenu">
        <li class="menu-list">
          <a
            class="menu-link"
            routerLink="campaigns"
            aria-label="Campaigns"
            routerLinkActive="active-link"
            ariaCurrentWhenActive="page"
            [matTooltip]="'Campaigns'"
            [matTooltipPosition]="'right'">
            <app-ce-icon
              [name]="'filter'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
          </a>
        </li>
        <li class="menu-list">
          <a
            class="menu-link"
            routerLink="segments"
            aria-label="Segments"
            routerLinkActive="active-link"
            ariaCurrentWhenActive="page"
            [matTooltip]="'Segments'"
            [matTooltipPosition]="'right'">
            <app-ce-icon
              [name]="'coupon'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
          </a>
        </li>
        <li class="menu-list">
          <a
            class="menu-link"
            routerLink="loyalty"
            aria-label="loyalty"
            routerLinkActive="active-link"
            ariaCurrentWhenActive="page"
            [matTooltip]="'Loyalty'"
            [matTooltipPosition]="'right'">
            <app-ce-icon
              [name]="'certificate'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
          </a>
        </li>
        <li class="menu-list">
          <a class="menu-link" routerLinkActive="active-link" ariaCurrentWhenActive="page">
            <app-ce-icon
              [name]="'chat'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
            <ul
              class="hover-menu last-menu custom-dropdown border-radius side-nav-list h3-bold primary-100">
              <li>
                <a class="cursor-pointer" routerLink="conversations"
                  ><span
                    ><app-ce-icon
                      [name]="'chat'"
                      [svg_class]="['cursor-pointer', 'primary-50']"></app-ce-icon></span
                  >Conversations</a
                >
              </li>
              <li>
                <a class="cursor-pointer" routerLink="tickets"
                  ><span
                    ><app-ce-icon
                      [name]="'note'"
                      [svg_class]="['cursor-pointer', 'primary-50']"></app-ce-icon></span
                  >Tickets</a
                >
              </li>
              <li>
                <a class="cursor-pointer" routerLink="faqs"
                  ><span
                    ><app-ce-icon
                      [name]="'help'"
                      [svg_class]="['cursor-pointer', 'primary-50']"></app-ce-icon></span
                  >FAQs</a
                >
              </li>
            </ul>
          </a>
        </li>
        <li class="menu-list">
          <a
            class="menu-link"
            ariaCurrentWhenActive="page"
            aria-label="Media"
            (click)="openGallery()"
            [matTooltip]="'Gallery'"
            [matTooltipPosition]="'right'">
            <app-ce-icon
              [name]="'camera'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
          </a>
        </li>
      </ul>
    </div>
    <div class="bottom-nav">
      <ul class="sideMenu">
        <li class="menu-list">
          <a
            class="menu-link"
            routerLink="settings"
            aria-label="settings"
            routerLinkActive="active-link"
            ariaCurrentWhenActive="page"
            [matTooltip]="'Settings'"
            [matTooltipPosition]="'right'">
            <app-ce-icon
              [name]="'settings'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
}
@if (settings == menuSettings.OrganisationSetting) {
  <div class="side-nav">
    <div class="logo">
      <img class="cursor-pointer" height="27" width="24" alt="" ngSrc="/assets/logo.svg" priority />
    </div>
    <div class="side-nav-top">
      <ul class="sideMenu">
        <li class="menu-list">
          <a
            class="menu-link"
            routerLink="/organisation"
            aria-label="Organisation"
            routerLinkActive="active-link"
            ariaCurrentWhenActive="page"
            [matTooltip]="'Organisation'"
            [matTooltipPosition]="'right'">
            <app-ce-icon
              [name]="'settings'"
              [svg_class]="['cursor-pointer', 'main-menu']"></app-ce-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
}
