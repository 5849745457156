import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { TableTabComponent } from '../table-tab/table-tab.component';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    ButtonsComponent,
    SecondaryBtnsComponent,
    LinkBtnComponent,
    TableTabComponent,
  ],
})
export class NotificationsComponent implements OnInit {
  // @Output() seeAll = new EventEmitter<any>();

  seeAll() {
    this.router.navigateByUrl('notification-center');
  }
  constructor(private router: Router) {}
  ngOnInit(): void {}
  @Input()
  tabContent = [
    {
      label: 'Notifications',
      id: 'notification-tab-pane',
      active: true,
    },
    {
      label: 'Activities',
      id: 'activities-tab-pane',
      active: false,
    },
  ];
}
