import { Component, OnDestroy } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { StoreSettingsService } from '@services/setting-services/store-settings.service';
import { UserSettingsService } from '@services/core-services/user-settings.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FilterGroupType, Operator } from '@services/core-services/filters';

@Component({
  selector: 'app-sse',
  imports: [],
  templateUrl: './sse.component.html',
  styleUrl: './sse.component.scss',
  providers: [AngularFirestore],
})
export class SSEComponent implements OnDestroy {
  private readonly configEventSubscription: SubscriptionLike;

  constructor(
    private readonly storeSettingsService: StoreSettingsService,
    private readonly userSettingsService: UserSettingsService
  ) {
    this.configEventSubscription = this.storeSettingsService
      .connectToServerSentEvents()
      .subscribe();

    this.userSettingsService.watch().subscribe({
      next: settings => console.log('User settings', settings),
    });

    // setTimeout(() => {
    //   this.userSettingsService.set({
    //     ['filters:products']: [
    //       {
    //         "name": "Custom filter 1",
    //         "filters": [
    //           {
    //             "type": "data",
    //             "field": "active",
    //             "operator": "equals",
    //             "value": true
    //           }
    //         ]
    //       },
    //       {
    //         "name": "Custom filter 2",
    //         "filters": [
    //           {
    //             "type": "data",
    //             "field": "name",
    //             "operator": "contains",
    //             "value": "and*"
    //           }
    //         ]
    //       }
    //     ]
    //   });
    // }, 5000);
  }

  ngOnDestroy() {
    this.configEventSubscription.unsubscribe();
    this.storeSettingsService.disconnectServerSentEvents();
  }
}
