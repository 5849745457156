<div class="custom-dropdown spacing-12 border-radius radius-top-right">
  <div class="media">
    <div class="media-left">
      <div class="avatar">
        @if (userImg) {
          <img
            class="rounded-circle"
            height="32"
            width="32"
            alt="{{ userName }}'s avatar"
            ngSrc="{{ userImg }}" />
        } @else {
          <span class="h4-bold text-avtar">{{ userName | slice: 0 : 1 }}</span>
        }
      </div>
    </div>
    <div class="media-body">
      <h4 class="h3-bold m-0 d-flex align-items-center primary-100 text-captalize">
        {{ userName }}
      </h4>
      <span class="h4-reg primary-60">{{ userEmail }}</span>
    </div>
  </div>
  <div class="border-line"></div>
  <ul class="dropdown-items">
    <dropdown-single-piece
      class="cursor-pointer"
      [label]="'Organization settings'"
      [color]="'default'"
      [type]="'bold'"
      [icon]="false"
      iconType=""
      [dragDrop]="false"
      [toggle]="false"
      [more]="false"
      [radio]="false"
      routerLink="/organisation"
      ariaCurrentWhenActive="page"
      routerLinkActive="router-link-active"></dropdown-single-piece>
    <dropdown-single-piece
      [label]="'Support'"
      [color]="'default'"
      [type]="'bold'"
      [state]="'default'"
      [icon]="false"
      iconType=""
      [dragDrop]="false"
      [toggle]="false"
      [more]="false"
      [radio]="false"></dropdown-single-piece>

    <li class="p-0">
      <dropdown-single-piece
        [label]="'Theme'"
        [color]="'default'"
        [type]="'bold'"
        [state]="'default'"
        [icon]="false"
        iconType=""
        [dragDrop]="false"
        [toggle]="false"
        [more]="false"
        [radio]="false"></dropdown-single-piece
      ><app-box-tab
        tabId="myTab"
        Size="medium"
        [boxContent]="themes"
        (selectedTab)="setTheme($event)"></app-box-tab>
    </li>
    <dropdown-single-piece
      [label]="'Report a bug'"
      [color]="'red'"
      [type]="'bold'"
      [state]="'default'"
      [icon]="true"
      [iconType]="'sound_off'"
      [dragDrop]="false"
      [toggle]="false"
      [more]="false"
      [radio]="false"
      (click)="this.emitClick('report-bug')"></dropdown-single-piece>
    <dropdown-single-piece
      (click)="this.emitClick('logout')"
      [label]="'Logout'"
      [color]="'default'"
      [type]="'bold'"
      [state]="'default'"
      [icon]="false"
      iconType=""
      [dragDrop]="false"
      [toggle]="false"
      [more]="false"
      [radio]="false"></dropdown-single-piece>
  </ul>
</div>
