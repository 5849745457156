import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { delayWhen, interval, of, switchMap, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SignupMode, SignupService } from '@services/onboarding-services/signup.service';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { InputsComponent } from '@enginuity/core/molecules/inputs/inputs.component';
import { Tenant, TenantCreate } from '@services/tenant-services/models';

const CREATE_ORGANIZATION_DELAY = 3000;

@Component({
  selector: 'app-signup-organization-form',
  imports: [ButtonsComponent, InputsComponent, ReactiveFormsModule],
  templateUrl: './signup-organization-form.component.html',
  styleUrl: './signup-organization-form.component.scss',
})
export class SignupOrganizationFormComponent implements OnChanges {
  private tenant: Tenant | undefined;

  @Input({ required: true }) tenantCreate: TenantCreate | undefined | null;

  @Output() onOrganizationCreateStart = new EventEmitter();
  @Output() onOrganizationCreateSuccess = new EventEmitter<Tenant>();
  @Output() onOrganizationCreateError = new EventEmitter();

  protected form = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
    subdomain: [
      '',
      [
        Validators.required,
        Validators.pattern('^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*[a-z0-9]+$'),
        Validators.minLength(4),
      ],
    ],
  });

  constructor(
    private readonly fb: FormBuilder,
    private readonly signupService: SignupService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const tenantCreate = changes['tenantCreate']?.currentValue as TenantCreate;

    if (tenantCreate) {
      this.form.patchValue({
        name: tenantCreate.name || '',
        subdomain: tenantCreate.subdomain || '',
      });
    }
  }

  setupOrganization() {
    const { name, subdomain } = this.form.getRawValue();
    this.signupService
      .checkSubdomainAvailability(subdomain!)
      .pipe(
        tap(availability => {
          if (!availability.is_available) {
            throw { code: 'duplicate' };
          }
        }),
        tap(() => this.onOrganizationCreateStart.emit()),
        switchMap(() => this.signupService.createTenant(name!, subdomain!)),
        delayWhen(() => interval(CREATE_ORGANIZATION_DELAY)),
        tap(tenant => (this.tenant = tenant)),
        // TODO: Google Auth cannot support wild card subdomain matching
        // switchMap(() => this.signupService.loginUserWithEmailAndPassword()),
        tap(() => this.handleCreateSuccess(this.tenant)),
        catchError(err => this.handleCreateError(err))
      )
      .subscribe();
  }

  getState(name: string): 'default' | 'inactive' | 'success' | 'warning' | 'focused' {
    const control = this.form.get(name);
    const touched = control?.touched;

    return touched && control?.errors ? 'warning' : 'default';
  }

  getErrors(name: string) {
    const control = this.form.get(name);
    const errors = control?.errors;
    if (!errors) return null;

    if (errors?.['duplicate']) {
      return 'This domain is already taken';
    }

    if (errors?.['pattern']) {
      return 'Only letters and numbers allowed';
    }

    if (errors?.['minlength']) {
      return 'At least 4 characters are required';
    }

    if (errors?.['maxlength']) {
      return 'Maximum of 20 characters are required';
    }

    if (errors?.['required']) return 'This field is required`';

    return null;
  }

  private handleCreateError(err: any) {
    if (err?.code === 'duplicate') {
      this.form.get('subdomain')?.setErrors({ duplicate: 'true' });
    } else {
      setTimeout(() => this.signupService.setSignupMode(SignupMode.Error), 3000);
    }

    return of(null);
  }

  private handleCreateSuccess(tenant: Tenant | undefined) {
    this.signupService.continueAfterOrganizationCreate(tenant);
  }
}
