<!-- Signup Option Selection -->

@if (mode === SignupMode.MethodSelection) {
  <div class="panel">
    <app-signup-options></app-signup-options>
  </div>
}

<!-- Email SignUp Screen -->

@if (mode === SignupMode.SignupWithEmail) {
  <div class="panel">
    <app-onboarding-header>
      <span class="h3-reg primary-60">Already have an account?</span>
      <app-link-btn
        label="Sign in"
        routerLink="/signin"
        IconType="panel_arrow_right"></app-link-btn>
    </app-onboarding-header>

    <app-onboarding-title title="Sign up">
      We are building the future of commerce! Sign up now to take your business to new heights with
      Commerce Engine.
    </app-onboarding-title>

    <app-signup-email-form class="w-100" (onSignUp)="onSignUp()"></app-signup-email-form>
  </div>
}

<!-- OTP Verification Screen -->

@if (mode === SignupMode.Verification) {
  <div class="panel">
    <app-onboarding-header [centered]="true"></app-onboarding-header>

    <app-onboarding-title title="Confirm email" align="center">
      Enter the one-time password we’ve sent to your email
    </app-onboarding-title>

    <app-signup-otp-form
      [user]="user$ | async"
      [otp]="otp$ | async"
      (onEmailVerified)="onEmailVerified()"></app-signup-otp-form>
  </div>
}

<!-- Setup Organization Screen -->

@if (mode === SignupMode.Organization) {
  <div class="panel">
    <app-onboarding-header [centered]="true"></app-onboarding-header>

    <app-onboarding-title title="Create a unique address for your Organisation" align="center">
      Set up a custom web address to access and manage your organisation.
    </app-onboarding-title>

    <app-signup-organization-form
      [tenantCreate]="tenantCreate$ | async"
      (onOrganizationCreateStart)="onOrganizationCreateStart()"
      (onOrganizationCreateSuccess)="onOrganizationCreateSuccess($event)"
      (onOrganizationCreateError)="onOrganizationCreateError()">
    </app-signup-organization-form>
  </div>
}

<!-- Processing Screen -->

@if (mode === SignupMode.Creating) {
  <app-logo-loader
    title="Setting Up Your Cockpit..."
    message="We're getting everything ready for you to take control of your organization and store. This won’t take long!">
  </app-logo-loader>
}

<!-- Organization & Store create Done Screen -->

@if (mode === SignupMode.Success) {
  <app-logo-loader
    title="Cockpit Ready for Takeoff 🚀"
    message="You’re all set. Redirecting you to the next step...">
  </app-logo-loader>
}

<!-- Organization & Store create Error Screen -->

@if (mode === SignupMode.Error) {
  <div class="panel">
    <app-onboarding-header [centered]="true"></app-onboarding-header>

    <app-onboarding-title title="Oops! Bad Weather" align="center">
      Something went wrong while setting up your cockpit. Let’s try this one more time
    </app-onboarding-title>

    <app-buttons
      (btnClick)="retrySetup()"
      [active]="false"
      class="w-100"
      label="Retry setup"
      size="large"
      IconType=""
      [shortcutKey]="false"></app-buttons>
  </div>
}
