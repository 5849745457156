<div class="dropdown-menu-media dropdown-menu-right d-block notification-card">
  <!-- Notifications header -->
  <div class="dropdown-menu-header">
    <div class="dropdown-header d-flex justify-content-between h3-bold p-3">
      <h4 class="notification-title mb-0 mr-auto h3-bold">Notifications</h4>
      <div class="mark-btn">
        <app-link-btn
          label="Mark all as read"
          [active]="false"
          size="large"
          IconType="none"
          [disabled]="false">
        </app-link-btn>
      </div>
    </div>
    <div class="notification-header">
      <app-table-tab
        [withIcon]="false"
        [state]="'classic'"
        [tabContent]="tabContent"
        Type="tag"></app-table-tab>
    </div>
  </div>
  <!--/ Notifications header -->

  <!-- Notifications content -->
  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade"
      [ngClass]="{ 'show active': tabContent[0]['active'] }"
      id="notification-tab-pane"
      role="tabpanel"
      aria-labelledby="notification-tab"
      tabindex="0">
      <div class="media d-flex align-items-start p-16 notiy-sec">
        <div class="media-left">
          <div class="avatar">
            <span class="custom-icon">🤩</span>
          </div>
        </div>
        <div class="media-body">
          <h4 class="h3-bold mb-8">
            Koman Godar <span class="h3-reg">requested access to</span> UNIX directory tree
            <span class="h3-reg">hierarchy</span>
          </h4>
          <div class="controls d-flex">
            <app-buttons
              [active]="false"
              [disabled]="false"
              [shortcutKey]="false"
              label="Accept"
              size="small"
              Type="withoutIcon">
            </app-buttons>
            <app-secondary-btns
              [active]="false"
              [IconValue]="''"
              [disabled]="false"
              label="Reject"
              size="small"
              Type="withoutIcon">
            </app-secondary-btns>
          </div>
          <div class="time">
            <span class="pt-16 h3-reg">Today at 9:42 AM</span>
          </div>
        </div>
      </div>
      <div class="media d-flex align-items-start p-16 notiy-sec">
        <div class="media-left">
          <div class="avatar">
            <span class="custom-icon">😎</span>
          </div>
        </div>
        <div class="media-body">
          <h4 class="h3-bold mb-8 pb-15">
            Lex Murphy <span class="h3-reg">sent you a message</span>
          </h4>
          <div class="info-panel blockquote">
            <p class="h4-reg primary-60">
              “Oh, I finished de-bugging the phones, but the system's compiling for eighteen
              minutes, or twenty. So, some minor systems may go on and off for a while.”
            </p>
          </div>
          <div class="time">
            <span class="pt-16 h3-reg">Today at 9:42 AM</span>
          </div>
        </div>
      </div>
      <div class="media d-flex align-items-start p-16 notiy-sec border-0">
        <div class="media-left">
          <div class="avatar">
            <span class="custom-icon">🤩</span>
          </div>
        </div>
        <div class="media-body">
          <h4 class="h3-bold mb-8">
            Jessica Garry
            <span class="h3-reg"> wants to buy the product</span> The Product Name
          </h4>
          <div class="controls d-flex">
            <app-buttons
              [active]="false"
              [disabled]="false"
              [shortcutKey]="false"
              label="Accept"
              size="small"
              Type="withoutIcon">
            </app-buttons>
            <app-secondary-btns
              [active]="false"
              [IconValue]="''"
              [disabled]="false"
              label="Reject"
              size="small"
              Type="withoutIcon">
            </app-secondary-btns>
          </div>
          <div class="time">
            <span class="pt-16 h3-reg">Today at 9:42 AM</span>
          </div>
        </div>
      </div>
      <div class="dropdown-menu-footer p-12">
        <app-secondary-btns
          [active]="false"
          (onClick)="seeAll()"
          [disabled]="false"
          label="See all notifications"
          size="large"
          Type="withoutIcon"
          IconValue=""></app-secondary-btns>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="activities-tab-pane"
      [ngClass]="{ 'show active': tabContent[1]['active'] }"
      role="tabpanel"
      aria-labelledby="activities-tab"
      tabindex="0">
      <div class="activity-month">
        <div class="h4-reg primary-60 ps-3 pt-3 mb-8">September</div>
        <div class="media d-flex align-items-start p-16 notiy-sec">
          <div class="media-left">
            <div class="avatar">
              <span class="icon primary-icon"><img alt="" src="/assets/change.svg" /></span>
            </div>
          </div>
          <div class="media-body">
            <h4 class="h3-bold">New brand and support pages <span class="h3-reg">✨</span></h4>
            <div class="info-panel mb-8">
              <p class="h4-reg primary-60">
                “Oh, I finished de-bugging the phones, but the system's compiling for eighteen
                minutes, or twenty. So, some minor systems may go on and off for a while.”
              </p>
            </div>
            <div class="time">
              <span class="h4-reg primary-70">14 September 2022 at 2:30 PM</span>
            </div>
          </div>
        </div>
        <div class="media d-flex align-items-start p-16 notiy-sec">
          <div class="media-left">
            <div class="avatar">
              <span class="icon warning-icon"><img alt="" src="/assets/warning.svg" /></span>
            </div>
          </div>
          <div class="media-body">
            <h4 class="h3-bold">Payment declined <span class="h3-reg">🛑</span></h4>
            <div class="time">
              <span class="h4-reg primary-70">14 September 2022 at 2:30 PM</span>
            </div>
          </div>
        </div>
        <div class="media d-flex align-items-start p-16 notiy-sec">
          <div class="media-left">
            <div class="avatar">
              <span class="icon alert-icon"><img alt="" src="/assets/person-alert.svg" /></span>
            </div>
          </div>
          <div class="media-body">
            <h4 class="h3-bold">Authorize your account <span class="h3-reg">🙏</span></h4>
            <div class="time">
              <span class="h4-reg primary-70">14 September 2022 at 2:30 PM</span>
            </div>
          </div>
        </div>
      </div>
      <div class="activity-month">
        <div class="h4-reg primary-60 ps-3 pt-3 mb-8">August</div>
        <div class="media d-flex align-items-start p-16 notiy-sec">
          <div class="media-left">
            <div class="avatar">
              <span class="icon success-icon"
                ><svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 8.18a1 1 0 0 0-1-1H5.5a1 1 0 1 0 0 2H7v4a1 1 0 1 0 2 0v-5zm-5-8h8a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4v-8a4 4 0 0 1 4-4zm4 5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
                    fill="" />
                </svg>
              </span>
            </div>
          </div>
          <div class="media-body">
            <h4 class="h3-bold">Announcment <span class="h3-reg">👀</span></h4>
            <div class="info-panel mb-8">
              <p class="h4-reg primary-60">
                “Oh, I finished de-bugging the phones, but the system's compiling for eighteen
                minutes, or twenty. So, some minor systems may go on and off for a while.”
              </p>
            </div>
            <div class="time">
              <span class="h4-reg primary-70">14 September 2022 at 2:30 PM</span>
            </div>
          </div>
        </div>
      </div>
      <div class="dropdown-menu-footer p-16">
        <app-secondary-btns
          [active]="false"
          [disabled]="false"
          label="See all activities"
          size="large"
          Type="withoutIcon"
          IconValue=""></app-secondary-btns>
      </div>
    </div>
  </div>
  <!--/ Notifications content -->

  <!-- Notifications footer -->

  <!--/ Notifications footer -->
</div>
