<nav aria-label="breadcrumb">
  <ol class="breadcrumb spacing-8">
    @for (crumb of breadcrumb; track crumb.link; let i = $index) {
      <li class="breadcrumb-item h4-case f-14 dropdown" [ngClass]="{ active: !crumb.current }">
        <div [cdkMenuTriggerFor]="menu">
          <div
            class="bredcrumb-items text-capitalize"
            href="#"
            role="button"
            (click)="changeUrl(crumb.link)">
            <span
              ><app-ce-icon [name]="crumb.icon" [svg_class]="['primary-50']"></app-ce-icon
            ></span>
            {{ crumb.label }}
          </div>
        </div>
        <ng-template #menu>
          @if (crumb.dropdown) {
            <div class="mt-3" cdkMenu>
              <div class="custom-dropdown border-radius radius-top-right radius-top-left">
                @for (item of crumb.menuItems; track item.link; let i = $index) {
                  <dropdown-single-piece
                    class="w-100 cursor-pointer"
                    [routerLink]="item.link"
                    #breadcrumbItem
                    (keydown)="navigateOnKeydown($event, item.link)"
                    label="{{ item.name }}"
                    [color]="'default'"
                    [type]="'bold'"
                    [icon]="true"
                    iconType="{{ item.icon }}"
                    [dragDrop]="false"
                    [toggle]="false"
                    [more]="false"
                    [radio]="false"
                    cdkMenuItem></dropdown-single-piece>
                }
              </div>
            </div>
          }
        </ng-template>
      </li>
      @if (i < breadcrumb.length - 1) {
        <li class="seprate h4-reg primary-50"><span>/</span></li>
      }
    }
  </ol>
</nav>
