import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { AuthError } from '@services/auth-services/models';
import { SignupMode, SignupService } from '@services/onboarding-services/signup.service';
import { SignupUser } from '@services/onboarding-services/models';
import { SignupEmailFormComponent } from '@enginuity/onboarding/organisms/signup-email-form/signup-email-form.component';
import { SignupOptionsComponent } from '@enginuity/onboarding/organisms/signup-options/signup-options.component';
import { SignupOtpFormComponent } from '@enginuity/onboarding/organisms/signup-otp-form/signup-otp-form.component';
import { SignupOrganizationFormComponent } from '@enginuity/onboarding/organisms/signup-organization-form/signup-organization-form.component';
import { OnboardingHeaderComponent } from '@enginuity/onboarding/organisms/onboarding-header/onboarding-header.component';
import { OnboardingTitleComponent } from '@enginuity/onboarding/organisms/onboarding-title/onboarding-title.component';
import { RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { OTPToken, Tenant, TenantCreate } from '@services/tenant-services/models';
import { LogoLoaderComponent } from '@enginuity/core/organisms/logo-loader/logo-loader.component';
import { UrlService } from '@services/auth-services/url.service';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  imports: [
    CommonModule,
    LinkBtnComponent,
    SignupEmailFormComponent,
    SignupOptionsComponent,
    SignupOtpFormComponent,
    SignupOrganizationFormComponent,
    OnboardingHeaderComponent,
    OnboardingTitleComponent,
    RouterLink,
    LogoLoaderComponent,
    ButtonsComponent,
  ],
  providers: [SignupService],
})
export class SignupComponent {
  protected readonly SignupMode = SignupMode;
  protected error: AuthError | undefined;
  protected processing = false;
  protected mode: SignupMode = SignupMode.SignupWithEmail;
  protected user$: Observable<SignupUser | undefined>;
  protected tenantCreate$: Observable<TenantCreate | undefined>;
  protected otp$: Observable<OTPToken | undefined>;

  constructor(
    private readonly urlService: UrlService,
    private readonly signupService: SignupService
  ) {
    this.user$ = this.signupService.getUser();
    this.otp$ = this.signupService.getOTP();
    this.tenantCreate$ = this.signupService.getTenantCreate();
    this.signupService.getSignupMode().subscribe(mode => (this.mode = mode));
  }

  onSignUp() {
    this.signupService.setSignupMode(SignupMode.Verification);
  }

  onEmailVerified() {
    this.signupService.setSignupMode(SignupMode.Organization);
  }

  onOrganizationCreateStart() {
    this.signupService.setSignupMode(SignupMode.Creating);
  }

  onOrganizationCreateSuccess(tenant: Tenant) {
    this.signupService.setSignupMode(SignupMode.Success);
    this.urlService.redirectToPath(tenant.subdomain, 'onboarding/store');
  }

  onOrganizationCreateError() {
    this.signupService.setSignupMode(SignupMode.Error);
  }

  retrySetup() {
    this.signupService.setSignupMode(SignupMode.Organization);
  }
}
