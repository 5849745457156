<div class="onboarding-flow">
  <div class="colored-box light-green" style="top: 526px; left: 106px"></div>
  <div class="colored-box light-green" style="top: 0px; left: 421px"></div>
  <div class="colored-box light-green" style="top: 106px; left: 2100px"></div>
  <div class="onboarding-header">
    <div class="top-nav">
      <div class="breadcrumb-section align-items-center d-none d-md-flex spacing-12">
        <img
          class="d-flex logo cursor-pointer"
          ngSrc="/assets/ce-full-dark-logo.svg"
          height="35"
          width="130"
          priority />
        <span class="domain-name h3-bold primary-0">{{ urlSettings?.domain }}</span>
      </div>
      <span
        class="m-domain-name w-100 h4-bold primary-80 align-items-center justify-content-center"
        >{{ urlSettings?.domain }}</span
      >
    </div>
  </div>

  <div id="setup-organization" class="add-layout">
    <div class="block-item" [@fadeInOut]>
      <div class="p-0 onboarding-sec">
        <div class="d-flex align-items-start flex-column spacing-16 justify-content-start">
          <div class="onboarding-grid p-24 spacing-24">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
