import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CeIconComponent } from '@enginuity/core/atoms/ce-icon/ce-icon.component';
import { ModalService } from '@services/core-services/modal.service';
import { MediaGalleryDialogComponent } from '@enginuity/media/organisms/media-gallery-dialog/media-gallery-dialog.component';
import { ProtectionService } from '@services/core-services/protection.service';
import { MenuSettings } from '@services/setting-services/models/menu-settings.enum';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  imports: [CommonModule, CeIconComponent, NgOptimizedImage, RouterModule, MatTooltipModule],
})
export class SideNavComponent implements OnInit {
  store_slug: string = '';

  constructor(
    public route: ActivatedRoute,
    private readonly router: Router,
    private readonly modal: ModalService,
    private readonly protectionService: ProtectionService
  ) {}

  menuSettings = MenuSettings;
  @Input()
  settings: MenuSettings.OrganisationSetting | MenuSettings.StoreSetting =
    MenuSettings.OrganisationSetting;

  ngOnInit() {
    this.store_slug = this.route.snapshot.paramMap.get('store_slug') || 'default-slug';
  }

  appInitializer() {
    return () => {
      document.body.classList.add('app-loaded'); // Add your class here
    };
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const keyCode = event.key;

    if (keyCode === 'ArrowUp') {
      this.navigateUp();
    } else if (keyCode === 'ArrowDown') {
      this.navigateDown();
    }
  }

  navigateUp() {
    const menuItems = document.querySelectorAll('.menu-item');
    const currentIndex = Array.from(menuItems).findIndex(item => item.classList.contains('active'));

    if (currentIndex > 0) {
      const previousMenuItem = menuItems[currentIndex - 1] as HTMLElement;
      previousMenuItem.focus();
    }
  }

  navigateDown() {
    const menuItems = document.querySelectorAll('.menu-item');
    const currentIndex = Array.from(menuItems).findIndex(item => item.classList.contains('active'));

    if (currentIndex < menuItems.length - 1) {
      const nextMenuItem = menuItems[currentIndex + 1] as HTMLElement;
      nextMenuItem.focus();
    }
  }

  openGallery() {
    this.modal.open(MediaGalleryDialogComponent, {
      panelClass: 'modal-gallery-dialog',
      backdropClass: 'modal-gallery-window',
    });
  }
}
