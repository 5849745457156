@if (loaderState === LoaderState.InProgress || loaderState === LoaderState.Error) {
  <app-global-loader
    [title]="loaderContent?.title"
    [message]="loaderContent?.message"></app-global-loader>
} @else {
  <router-outlet></router-outlet>
}

<!-- Server Side Events watcher -->
@if (user) {
  <app-sse></app-sse>
}
