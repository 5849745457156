import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@services/auth-services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // TODO: Refresh token flow
  /* private isRefreshing = false;
   private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);*/

  constructor(public authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this.authService.getCurrentUser();
    if (user?.accessToken) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${user?.accessToken}` },
      });
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (
          error instanceof HttpErrorResponse &&
          !request.url.includes('/login') &&
          error.status === 401
        ) {
          console.log(request, error);
        } else if (error.status === 403) {
          // TODO: Handle error
        }
        return throwError(error);
      })
    );
  }
}
