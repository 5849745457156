<form [formGroup]="form" class="w-100 d-flex flex-column spacing-32">
  <div class="w-100 d-flex flex-column spacing-16">
    <app-inputs
      type="text"
      formControlName="name"
      placeholder="Provide organisation name"
      class="w-100"
      Label="Organisation name"
      [state]="getState('name')"
      [error]="getErrors('name')"></app-inputs>

    <app-inputs
      type="text"
      formControlName="subdomain"
      placeholder="Set up subdomain"
      class="w-100"
      Label="Subdomain"
      [description]="'Choose your organisation’s address (e.g., mybrand)'"
      [info]="true"
      [state]="getState('subdomain')"
      [error]="getErrors('subdomain')"></app-inputs>
  </div>

  <!--
  TODO: Domain availability error handling
  <span class="error-warning h4-reg" *ngIf="availability && !availability.is_available">
    <app-ce-icon [name]="'alert'" [svg_class]="['d-flex']"></app-ce-icon>
    This domain is already taken
  </span>-->

  <app-buttons
    (btnClick)="setupOrganization()"
    [active]="false"
    class="w-100"
    [disabled]="form.invalid"
    label="Create Organisation"
    size="large"
    IconType=""
    [shortcutKey]="false"></app-buttons>
</form>
