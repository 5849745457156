<app-secondary-btns
  class="w-100"
  [active]="false"
  [disabled]="false"
  label="Continue with email"
  size="large"
  Type="leftIcon"
  IconValue="⌘N"
  IconType="email"
  (click)="setSignupMode(SignupMode.SignupWithEmail)"></app-secondary-btns>

<div class="divider">
  <span class="or h4-case primary-60 text-uppercase">or</span>
</div>

<div class="social-login d-flex align-items-center spacing-16 w-100 flex-column">
  <!--Google Signup Button -->
  <app-secondary-btns
    class="w-100"
    [active]="false"
    [disabled]="false"
    label="Continue with Google"
    size="large"
    Type="leftIcon"
    IconValue="⌘N"
    IconType="google"
    (click)="setSignupOption('google')"></app-secondary-btns>

  <!--Microsoft Signup Button -->
  <app-secondary-btns
    class="w-100"
    [active]="false"
    [disabled]="false"
    label="Continue with Microsoft"
    size="large"
    Type="leftIcon"
    IconValue="⌘N"
    IconType="microsoft"
    (click)="setSignupOption('microsoft')"></app-secondary-btns>

  <!--GitHub Signup Button -->
  <app-secondary-btns
    class="w-100"
    [active]="false"
    [disabled]="false"
    label="Continue with GitHub"
    size="large"
    Type="leftIcon"
    IconValue="⌘N"
    IconType="github"></app-secondary-btns>
</div>
<div
  class="privacy-terms d-flex align-items-center justify-content-center spacing-12 flex-column w-100">
  <p class="m-0 p-0 primary-60 h3-reg">By creating an account, you agree to our</p>
  <ul class="m-0 p-0 d-flex align-items-center spacing-12">
    <li class="cursor-pointer h3-bold primary-100 d-flex" routerLink="">Privacy Policy</li>
    <span class="dot-divder"></span>
    <li class="cursor-pointer h3-bold primary-100 d-flex" routerLink="">Terms & Conditions</li>
  </ul>
</div>
