export const generatedAttribute = "generated";
export const mouseDownEvent = "pointerdown";
export const mouseUpEvent = "pointerup";
export const mouseLeaveEvent = "pointerleave";
export const mouseOutEvent = "pointerout";
export const mouseMoveEvent = "pointermove";
export const touchStartEvent = "touchstart";
export const touchEndEvent = "touchend";
export const touchMoveEvent = "touchmove";
export const touchCancelEvent = "touchcancel";
export const resizeEvent = "resize";
export const visibilityChangeEvent = "visibilitychange";
export const errorPrefix = "tsParticles - Error";
export const percentDenominator = 100;
export const halfRandom = 0.5;
export const millisecondsToSeconds = 1000;