import { Component, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppService } from '@services/core-services/app.service';
import {
  GlobalLoaderService,
  LoaderContent,
  LoaderState,
} from '@services/core-services/global-loader.service';
import { GlobalLoaderComponent } from '@enginuity/core/organisms/global-loader/global-loader.component';
import { User } from '@services/core-services/models';
import { AuthService } from '@services/auth-services/auth.service';
import { SSEComponent } from '@enginuity/core/organisms/sse/sse.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet, GlobalLoaderComponent, SSEComponent],
})
export class AppComponent {
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.appService.handleKeyShortcuts(event);
  }

  protected readonly LoaderState = LoaderState;
  protected loaderState: LoaderState = LoaderState.None;
  protected loaderContent: LoaderContent | undefined = undefined;
  protected user: User | undefined = undefined;

  constructor(
    private readonly appService: AppService,
    private readonly loaderService: GlobalLoaderService,
    private readonly authService: AuthService
  ) {
    this.loaderService.getContent().subscribe(content => (this.loaderContent = content));
    this.loaderService.getStatus().subscribe(status => (this.loaderState = status));
    this.authService.getUser().subscribe(user => (this.user = user));
  }
}
