import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export interface TimeProtectionEntry {
  count: number;
  countdown: number;
  blocked: boolean;
}

@Injectable({ providedIn: 'root' })
export class TimeProtectionService {
  private count: number = 0;
  private blocked: boolean = false;
  private countdownSubscription: Subscription | null = null;

  private entry: BehaviorSubject<TimeProtectionEntry> = new BehaviorSubject<TimeProtectionEntry>({
    blocked: false,
    count: 0,
    countdown: 60,
  });

  public blockAndStartCounting(time = 60) {
    this.count++;

    if (!this.blocked) {
      this.setBlocked(true);
      this.startCountdown(time);
    }
  }

  get valueChanges() {
    return this.entry.asObservable();
  }

  private startCountdown(time = 60) {
    this.entry.next({ countdown: time, count: this.count, blocked: this.blocked });
    this.countdownSubscription = interval(1000)
      .pipe(take(time))
      .subscribe(value => {
        const timeLeft = time - value - 1;
        this.entry.next({ countdown: timeLeft, count: this.count, blocked: this.blocked });

        if (timeLeft === 0) {
          this.setBlocked(false);
          this.entry.next({ countdown: timeLeft, count: this.count, blocked: this.blocked });
          this.countdownSubscription?.unsubscribe();
        }
      });
  }

  private setBlocked(state: boolean) {
    this.blocked = state;
  }
}
