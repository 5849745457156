import { Component, Input } from '@angular/core';
import { LogoLoaderComponent } from '@enginuity/core/organisms/logo-loader/logo-loader.component';

@Component({
  selector: 'app-global-loader',
  imports: [LogoLoaderComponent],
  templateUrl: './global-loader.component.html',
  styleUrl: './global-loader.component.scss',
})
export class GlobalLoaderComponent {
  @Input() title: string | undefined;
  @Input() message: string | undefined;
}
