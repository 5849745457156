import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BoxTabComponent } from '@enginuity/core/molecules/box-tab/box-tab.component';
import { DropdownSinglePieceComponent } from '@enginuity/core/molecules/dropdown-single-piece/dropdown-single-piece.component';
import { RouterModule, Router } from '@angular/router';
import { AppService, ThemeMode } from '@services/core-services/app.service';

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    BoxTabComponent,
    DropdownSinglePieceComponent,
    NgOptimizedImage,
  ],
})
export class ProfileDropdownComponent {
  constructor(
    private readonly appService: AppService,
    private router: Router
  ) {}
  @Output() linkClicked = new EventEmitter<any>();

  setTheme(theme: any) {
    this.appService.setThemeMode(theme);
  }
  @Input() userName: string = '';
  @Input() userEmail: any;
  @Input() userImg: any;

  @Input()
  themes: any[] = [
    {
      id: ThemeMode.Light,
      active: true,
      icon: 'light',
    },
    {
      id: ThemeMode.Dark,
      active: false,
      icon: 'dark_theme',
    },
  ];

  emitClick(event: any) {
    this.linkClicked.emit(event);
  }

  navigateRoute() {
    this.router.navigateByUrl(`/organisation`);
  }
}
