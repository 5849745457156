import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SideNavComponent } from '@enginuity/core/organisms/side-nav/side-nav.component';
import { TopNavComponent } from '@enginuity/core/organisms/top-nav/top-nav.component';
import { MenuSettings } from '@services/setting-services/models/menu-settings.enum';

@Component({
  selector: 'app-main-layout',
  imports: [RouterOutlet, SideNavComponent, TopNavComponent],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent {
  protected expand_sidebar: boolean = false;
  protected show_layout: boolean = true;
  protected topNav: any = MenuSettings.OrganisationSetting;
  protected sideNav: any = MenuSettings.StoreSetting;
}
