import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { SearchComponent } from '@enginuity/core/molecules/search/search.component';
import { BadgesComponent } from '@enginuity/core/molecules/badges/badges.component';
import { CeIconComponent } from '@enginuity/core/atoms/ce-icon/ce-icon.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  imports: [
    CommonModule,
    SecondaryBtnsComponent,
    LinkBtnComponent,
    SearchComponent,
    BadgesComponent,
    CeIconComponent,
  ],
})
export class MessagesComponent {
  @Input()
  sender = 'Marta Doe';
}
