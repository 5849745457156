<div class="search-dropdown">
  <app-table-tab
    [withIcon]="true"
    state="classic"
    [tabContent]="searchtab"
    Type="tag"></app-table-tab>
  <div class="results">
    <span class="result h4-reg">{{ result }} results</span>
    <div class="search-results">
      <div class="media">
        <div class="media-left">
          <div class="avatar">
            <span class="custom-icon">🤩</span>
          </div>
        </div>
        <div class="media-body">
          <h4 class="h3-bold m-0 d-flex align-items-center gap">
            Koman Godar
            <app-ce-icon [name]="'verified'" [svg_class]="['success-120']"></app-ce-icon>
          </h4>
          <span class="h4-reg">kaman&#64;gmail.com</span>
        </div>
      </div>
      <div class="media">
        <div class="media-left">
          <div class="avatar">
            <span class="custom-icon">🤩</span>
          </div>
        </div>
        <div class="media-body">
          <h4 class="h3-bold m-0 d-flex align-items-center gap">
            Kaman Williams
            <app-ce-icon [name]="'verified'" [svg_class]="['success-120']"></app-ce-icon>
          </h4>
          <span class="h4-reg">k.w&#64;example.com</span>
        </div>
      </div>
      <div class="media">
        <div class="media-left">
          <div class="avatar">
            <span class="custom-icon">🤩</span>
          </div>
        </div>
        <div class="media-body">
          <h4 class="h3-bold m-0 d-flex align-items-center gap">
            Kaman Darwin
            <app-ce-icon [name]="'verified'" [svg_class]="['success-120']"></app-ce-icon>
          </h4>
          <span class="h4-reg">darwin&#64;kaman.com</span>
        </div>
      </div>
    </div>
  </div>
</div>
