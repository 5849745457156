import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { LoginService } from '@services/auth-services/login.service';
import { UrlSettings } from '@services/auth-services/models';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('0.5s', style({ opacity: 1 }))]),
    ]),
  ],
  imports: [NgOptimizedImage, RouterOutlet],
})
export class LoginLayoutComponent implements OnInit {
  public ready: boolean = false;
  public urlSettings: UrlSettings | undefined;
  public domain: string | undefined;
  constructor(private readonly loginService: LoginService) {}

  ngOnInit(): void {
    this.urlSettings = this.loginService.getUrlSettings();
  }
}
