<div class="d-flex flex-column flex-row-fluid wrapper pt spacing-40">
  <div class="onboarding-flow">
    <div class="colored-box light-green" style="top: 526px; left: 106px"></div>
    <div class="colored-box light-green" style="top: 105px; left: 211px"></div>
    <div class="colored-box light-green" style="top: 106px; left: 2100px"></div>
    <div class="onboarding-panel">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
