<div class="dropdown-menu-media dropdown-menu-right d-block notification-card">
  <!-- Notifications header -->
  <div class="dropdown-menu-header">
    <div class="dropdown-header d-flex justify-content-between h3-bold p-3">
      <h4 class="notification-title mb-0 mr-auto h3-bold">Messages</h4>
      <div class="mark-btn">
        <app-link-btn
          label="Mark all as read"
          [active]="false"
          size="large"
          IconType="none"
          [disabled]="false">
        </app-link-btn>
      </div>
    </div>
  </div>
  <!--/ Notifications header -->

  <!-- Notifications content -->
  <div class="messages d-flex align-items-center justify-content-start flex-column">
    <div class="search p-16 w-100">
      <div
        class="w-100"
        app-search
        [active]="false"
        placeholder="Search here"
        [SearchBtn]="false"
        [disabled]="false"
        size="medium"></div>
    </div>

    <div class="message-tab">
      <div class="media d-flex align-items-start p-16 notiy-sec w-100">
        <div class="media-left">
          <div class="avatar">
            <span class="custom-icon">🤩</span>
          </div>
        </div>
        <div class="media-body w-100 d-flex align-items-start spacing-4 flex-column">
          <h4
            class="m-0 h4-bold primary-100 d-flex align-items-center justify-content-between w-100">
            {{ sender
            }}<span class="h4-reg d-flex align-items-center spacing-8 primary-60"
              ><app-ce-icon
                [name]="'jam_moon_f'"
                class="warning-120"
                [svg_class]="['warning-120', 'cursor-pointer']"></app-ce-icon
              >1d</span
            >
          </h4>
          <div class="reply primary-60">
            <span class="h4-case">You:</span> &nbsp;<span class="h4-reg-strike"
              >Sure thing, I’ll have a look today. They’re looking great!</span
            >
          </div>
        </div>
      </div>
      <div class="media d-flex align-items-start p-16 notiy-sec w-100">
        <div class="media-left">
          <div class="avatar">
            <span class="custom-icon">🤩</span>
          </div>
        </div>
        <div class="media-body w-100 d-flex align-items-start spacing-4 flex-column">
          <h4
            class="m-0 h4-bold primary-100 d-flex align-items-center justify-content-between w-100">
            <span class="title d-flex align-items-center spacing-4"
              >{{ sender }}
              <app-badges
                [withIcon]="false"
                Type="completed"
                IconType="user"
                label="3"
                [ellipsis]="true"></app-badges
            ></span>
            <span class="h4-reg d-flex align-items-center spacing-8 primary-60">5 min</span>
          </h4>
          <div class="reply primary-60">
            <span class="h4-reg-strike"
              >Hey Daryll, Katherine sent me over the latest doc. I just have a quick question about
              the...</span
            >
          </div>
          <!-- <div class="channels d-flex align-items-center spacing-4 primary-60">
            <app-badges [withIcon]="true" Type="completed" IconType="whatsapp" label="3" [ellipsis]="true"></app-badges>
            <app-badges [withIcon]="true" Type="completed" IconType="whatsapp" label="3" [ellipsis]="true"></app-badges>
          </div> -->
        </div>
      </div>
      <div class="media d-flex align-items-start p-16 notiy-sec w-100">
        <div class="media-left">
          <div class="avatar">
            <span class="custom-icon">🤩</span>
          </div>
        </div>
        <div class="media-body w-100 d-flex align-items-start spacing-4 flex-column">
          <h4
            class="m-0 h4-bold primary-100 d-flex align-items-center justify-content-between w-100">
            {{ sender
            }}<span class="h4-reg d-flex align-items-center spacing-8 primary-60"
              ><app-ce-icon
                [name]="'jam_moon_f'"
                [svg_class]="['warning-120', 'cursor-pointer']"></app-ce-icon
              >1d</span
            >
          </h4>
          <div class="reply primary-60">
            <span class="h4-reg-strike"
              >Hey Daryll, Katherine sent me over the latest doc. I just have a quick question about
              the...</span
            >
          </div>
        </div>
      </div>
      <div class="all-messages p-12">
        <app-secondary-btns
          [active]="false"
          [disabled]="false"
          label="See all messages"
          size="large"
          Type="withoutIcon"
          IconValue=""></app-secondary-btns>
      </div>
    </div>
  </div>
</div>
