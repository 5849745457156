import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { SignupMode, SignupService } from '@services/onboarding-services/signup.service';

@Component({
  selector: 'app-signup-options',
  imports: [RouterLink, SecondaryBtnsComponent],
  templateUrl: './signup-options.component.html',
  styleUrl: './signup-options.component.scss',
})
export class SignupOptionsComponent {
  protected readonly SignupMode = SignupMode;

  constructor(private readonly signupService: SignupService) {}

  setSignupMode(mode: SignupMode) {
    this.signupService.setSignupMode(mode);
  }

  setSignupOption(provider: string) {}
}
