import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CeIconComponent } from '@enginuity/core/atoms/ce-icon/ce-icon.component';
import { TableTabComponent } from '../table-tab/table-tab.component';

@Component({
  selector: 'app-active-search',
  templateUrl: './active-search.component.html',
  styleUrls: ['./active-search.component.scss'],
  imports: [CommonModule, TableTabComponent, CeIconComponent],
})
export class ActiveSearchComponent {
  @Input()
  withIcon = false;

  @Input()
  result = '3';

  @Input()
  searchtab: any[] = [
    {
      label: 'All',
      id: 'notification-tab-pane',
      active: true,
    },
    {
      label: 'Customers',
      id: 'customer-tab-pane',
      active: false,
    },
    {
      label: 'Order',
      id: 'order-tab-pane',
      active: false,
      icon: '23',
    },
    {
      label: 'Products',
      id: 'product-tab-pane',
      active: false,
      icon: '23',
    },
  ];
}
