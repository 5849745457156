import { getAsyncContextStrategy } from '../asyncContext/index.js';
import { getMainCarrier } from '../carrier.js';
import { getClient, getCurrentScope } from '../currentScopes.js';
import { isEnabled } from '../exports.js';
import '../tracing/errors.js';
import '../utils-hoist/version.js';
import '../utils-hoist/debug-build.js';
import { logger } from '../utils-hoist/logger.js';
import '../debug-build.js';
import '../utils-hoist/time.js';
import { getActiveSpan, spanToTraceHeader } from './spanUtils.js';
import { TRACEPARENT_REGEXP, generateSentryTraceHeader } from '../utils-hoist/tracing.js';
import { getDynamicSamplingContextFromSpan, getDynamicSamplingContextFromScope } from '../tracing/dynamicSamplingContext.js';
import '../utils-hoist/syncpromise.js';
import { dynamicSamplingContextToSentryBaggageHeader } from '../utils-hoist/baggage.js';

/**
 * Extracts trace propagation data from the current span or from the client's scope (via transaction or propagation
 * context) and serializes it to `sentry-trace` and `baggage` values to strings. These values can be used to propagate
 * a trace via our tracing Http headers or Html `<meta>` tags.
 *
 * This function also applies some validation to the generated sentry-trace and baggage values to ensure that
 * only valid strings are returned.
 *
 * @returns an object with the tracing data values. The object keys are the name of the tracing key to be used as header
 * or meta tag name.
 */
function getTraceData(options = {}) {
  const client = getClient();
  if (!isEnabled() || !client) {
    return {};
  }
  const carrier = getMainCarrier();
  const acs = getAsyncContextStrategy(carrier);
  if (acs.getTraceData) {
    return acs.getTraceData(options);
  }
  const scope = getCurrentScope();
  const span = options.span || getActiveSpan();
  const sentryTrace = span ? spanToTraceHeader(span) : scopeToTraceHeader(scope);
  const dsc = span ? getDynamicSamplingContextFromSpan(span) : getDynamicSamplingContextFromScope(client, scope);
  const baggage = dynamicSamplingContextToSentryBaggageHeader(dsc);
  const isValidSentryTraceHeader = TRACEPARENT_REGEXP.test(sentryTrace);
  if (!isValidSentryTraceHeader) {
    logger.warn('Invalid sentry-trace data. Cannot generate trace data');
    return {};
  }
  return {
    'sentry-trace': sentryTrace,
    baggage
  };
}

/**
 * Get a sentry-trace header value for the given scope.
 */
function scopeToTraceHeader(scope) {
  const {
    traceId,
    sampled,
    spanId
  } = scope.getPropagationContext();
  return generateSentryTraceHeader(traceId, spanId, sampled);
}
export { getTraceData };
