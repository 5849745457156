import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { inject } from '@angular/core';
import { ProtectionService } from '@services/core-services/protection.service';
import { ApplicationMode } from '@services/core-services/app.service';
import { UrlService } from '@services/auth-services/url.service';

const getAppModeSlug = (mode: ApplicationMode) => {
  return mode === ApplicationMode.Live ? 'p' : 's';
};

export const storeSlugGuard: CanActivateFn = (
  route,
  state
): Observable<boolean> | Promise<boolean> | boolean => {
  const protectionService = inject(ProtectionService);
  const urlService = inject(UrlService);
  const router = inject(Router);
  const storeSlug: any = route.paramMap.get('store_slug');
  const appModeSlug: any = route.paramMap.get('app_mode');

  if (storeSlug && appModeSlug) return true;

  return protectionService.getStoreAndApp().pipe(
    map(({ store, mode }) => {
      const storeFragment = storeSlug ? '' : store!.slug;
      const modeFragment = appModeSlug ? '' : getAppModeSlug(mode);
      const url = urlService.sanitizeUrl(`${storeFragment}/${modeFragment}${state.url}`);
      router.navigateByUrl(url, { replaceUrl: true });
      return false;
    })
  );
};
