import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  RouterModule,
  ActivatedRoute,
  Router,
  Event,
  NavigationEnd,
  UrlTree,
  PRIMARY_OUTLET,
  UrlSegmentGroup,
  UrlSegment,
} from '@angular/router';
import { CeIconComponent } from '@enginuity/core/atoms/ce-icon/ce-icon.component';
import { DropdownSinglePieceComponent } from '@enginuity/core/molecules/dropdown-single-piece/dropdown-single-piece.component';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';

@Component({
  selector: 'app-bredcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  imports: [
    CommonModule,
    CeIconComponent,
    RouterModule,
    DropdownSinglePieceComponent,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
  ],
})
export class BreadcrumbComponent implements OnInit {
  dropdown = true;
  storeSlug: string = '';
  appModeSlug: string = '';

  constructor(
    public activeRoute: ActivatedRoute,
    public router: Router
  ) {
    this.activeRoute.paramMap.subscribe(params => {
      this.storeSlug = params.get('store_slug') || '';
      this.appModeSlug = params.get('app_mode') || '';
    });
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        this.formatBreadcrumb(router.parseUrl(url));
      }
    });
    const url = router.url;
    this.formatBreadcrumb(router.parseUrl(url));
  }
  navigateLink(link: string) {
    this.router.navigateByUrl(link);
  }
  navigateOnKeydown(event: KeyboardEvent, link: string) {
    if (event.key === 'Enter') {
      this.router.navigateByUrl(link);
    }
  }
  breadcrumb_extra_data: any = {
    customers: {
      dropdown: true,
      menuItems: [
        { name: 'Add new customer', icon: 'add', link: '/customers/add' },
        { name: 'Customers', icon: 'list', link: '/customers' },
        { name: 'Visitors', icon: 'list', link: '/visitors' },
      ],
    },
    visitors: {
      dropdown: true,
      icon: 'person',
      menuItems: [{ name: 'Customers', icon: 'list', link: '/customers' }],
    },
    payments: {
      dropdown: true,
      menuItems: [{ name: 'Create Payment', icon: 'add', link: '/payments/add-payment' }],
    },
    orders: {
      dropdown: true,
      menuItems: [
        { name: 'Create Order', icon: 'add', link: '/orders/add-order' },
        { name: 'Orders', icon: 'list', link: '/orders' },
        { name: 'Refunds', icon: 'list', link: '/refunds' },
        { name: 'Replacements', icon: 'list', link: '/replacements' },
      ],
    },
    refunds: {
      dropdown: true,
      menuItems: [
        { name: 'Refunds', icon: 'list', link: '/refunds' },
        { name: 'Orders', icon: 'list', link: '/orders' },
        { name: 'Replacements', icon: 'list', link: '/replacements' },
      ],
    },
    replacements: {
      dropdown: true,
      menuItems: [
        { name: 'Replacements', icon: 'list', link: '/replacements' },
        { name: 'Orders', icon: 'list', link: '/orders' },
        { name: 'Refunds', icon: 'list', link: '/refunds' },
      ],
    },
    shipments: {
      dropdown: true,
      menuItems: [
        {
          name: 'Create Shipment',
          icon: 'add',
          link: '/shipments/add-shipment',
        },
        { name: 'Shipments', icon: 'list', link: '/shipments' },
      ],
    },
  };

  ngOnInit(): void {}

  @Input()
  create = 'Page';

  @Input()
  listname = 'Customer list';

  @Input()
  breadcrumb: any[] = [];

  changeUrl(url: string) {
    if (url) {
      this.router.navigateByUrl(url);
    }
  }

  formatBreadcrumb(tree: UrlTree) {
    const segmentGroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    if (segmentGroup) {
      const urlSegments: UrlSegment[] = segmentGroup.segments;

      const breadcrumb = [];
      const segment_path = [];

      for (const [i, segment] of urlSegments.entries()) {
        let breadcrumb_extra_data: any = {};
        if (this.breadcrumb_extra_data[segment.path]) {
          breadcrumb_extra_data = this.breadcrumb_extra_data[segment.path];
        }
        if (segment.path === this.storeSlug) {
          continue; // Skip to the next iteration
        }
        if (segment.path === this.appModeSlug) {
          continue; // Skip to the next iteration
        }

        if (segment.path) {
          segment_path.push(segment.path);
          if (!('menuItems' in breadcrumb_extra_data)) {
            breadcrumb_extra_data['link'] = segment_path.join('/');
          }
        }
        if (i < 3 && segment.path != 'store') {
          breadcrumb.push({
            label: segment.path,
            current: i == urlSegments.length - 1,
            action_btn: false,
            dropdown: false,
            ...breadcrumb_extra_data,
          });
        }
      }
      this.breadcrumb = breadcrumb;
    }
  }
}
