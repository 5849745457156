import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateFn,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { concatMap, from, last, Observable, of, takeWhile } from 'rxjs';
import { inject, Injector, runInInjectionContext } from '@angular/core';

export interface AsyncGuard extends CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree>;
}

export function orderedAsyncGuards(guards: CanActivateFn[]): CanActivateFn {
  return (route, state) => {
    const injectionContext = inject(Injector);

    return from(guards).pipe(
      concatMap(guard => {
        return runInInjectionContext(injectionContext, () => {
          const guardResult = guard(route, state);
          if (guardResult instanceof Observable) {
            return guardResult;
          } else if (guardResult instanceof Promise) {
            return from(guardResult);
          } else {
            return of(guardResult);
          }
        });
      }),
      takeWhile(value => value === true, true),
      last()
    );
  };
}
