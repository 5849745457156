import { SignupUser } from '@services/onboarding-services/models';
import { BehaviorSubject, from, tap } from 'rxjs';
import { AuthService } from '@services/auth-services/auth.service';
import { TenantService } from '@services/tenant-services/tenant.service';
import { Injectable } from '@angular/core';
import { OTPToken, Tenant, TenantCreate } from '@services/tenant-services/models';
import { UrlService } from '@services/auth-services/url.service';

export enum SignupMode {
  MethodSelection = 'MethodSelection',
  SignupWithEmail = 'SignupWithEmail',
  Verification = 'Verification',
  Organization = 'Organization',
  Creating = 'Creating',
  Success = 'Success',
  Error = 'Error',
}

/*
{
      'password': 'Pass123456&%',
      'id': 'ovXLYGM0zVgm1NSqziqCsaT5tBz1',
      'email': 'andrej.kaurin+31@gmail.com',
      'first_name': 'Andrej',
      'last_name': 'Kaurin',
      'is_tenant_created': false,
      'is_store_created': false,
      'is_store_setup_complete': false,
}
* */

@Injectable()
export class SignupService {
  private mode = new BehaviorSubject<SignupMode>(SignupMode.SignupWithEmail);
  private otp = new BehaviorSubject<OTPToken | undefined>(undefined);
  private tenant = new BehaviorSubject<Tenant | undefined>(undefined);
  private tenantCreate = new BehaviorSubject<TenantCreate | undefined>(undefined);
  private user = new BehaviorSubject<SignupUser | undefined>(undefined);

  constructor(
    private readonly authService: AuthService,
    private readonly urlService: UrlService,
    private readonly tenantService: TenantService
  ) {}

  /**
   * Get user observable
   */
  getUser() {
    return this.user.asObservable();
  }

  /**
   * Get OTP observable
   */
  getOTP() {
    return this.otp.asObservable();
  }

  /**
   * Get tenant create observable
   */
  getTenantCreate() {
    return this.tenantCreate.asObservable();
  }

  /**
   * Gets signup mode observable
   */
  getSignupMode() {
    return this.mode.asObservable();
  }

  /**
   *
   * @param mode
   */
  setSignupMode(mode: SignupMode) {
    this.mode.next(mode);
  }

  /**
   * Sign up user using basic credentials (email, password).
   * @param user
   */
  signUp(user: SignupUser) {
    return this.tenantService.signUpWithEmailAndPassword(user).pipe(
      tap(otp => this.otp.next(otp)),
      tap(() => this.user.next(user))
    );
  }

  /**
   * Sends email with verification instructions.
   * @param email
   */
  sendVerificationMail(email: string) {
    return this.tenantService.sendVerificationOTP(email!).pipe(tap(otp => this.otp.next(otp)));
  }

  /**
   * Verifies email address using OTP and OTP token.
   * @param otp
   * @param otp_token
   */
  verifyEmail(otp: string, otp_token: string) {
    const { password } = this.user.getValue() || { password: '' };
    return this.tenantService
      .verifyOTP(otp, otp_token)
      .pipe(tap(user => this.user.next({ ...user, password })));
  }

  /**
   * Checks for the subdomain (tenant alias) availability.
   * @param subdomain
   */
  checkSubdomainAvailability(subdomain: string) {
    return this.tenantService.checkSubdomainAvailability(subdomain);
  }

  /**
   * Creates a new tenant.
   * @param name
   * @param subdomain
   */
  createTenant(name: string, subdomain: string) {
    const user = this.user.getValue();
    const tenant: TenantCreate = {
      name,
      subdomain,
      user_uid: user?.id!,
    };

    // We need to save this to be able to retry
    this.tenantCreate.next(tenant);

    return this.tenantService.createTenant(tenant).pipe(tap(tenant => this.tenant.next(tenant)));
  }

  /**
   * Logins user into system now we know user and tenant.
   */
  loginUserWithEmailAndPassword() {
    const { email, password } = this.user.getValue()!;
    const { id } = this.tenant.getValue()!;
    return from(this.authService.loginWithEmailAndPassword(email, password, id));
  }

  /**
   * Continues after organization is created and user is logged in
   * @param tenant
   */
  continueAfterOrganizationCreate(tenant: Tenant | undefined) {
    this.setSignupMode(SignupMode.Success);
    this.urlService.redirectToPath(tenant!.subdomain, 'auth/login');
  }
}
